/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --popup-background: #0003;

    @include mobile {
        --popup-content-padding: 14px;
    }

    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-content-padding: 12px;
    --popup-max-height: 80%;

    @include desktop {
        --popup-min-width: 490px;
        --popup-content-padding: 24px;
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100vw);
    }
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 400;
    position: fixed;
    background-color: var(--popup-background);
    inset-inline-start: 0;
    inset-block-start: var(--header-total-height);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        align-items: stretch;
    }

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;
        inset-block-start: 0;

        @include mobile {
            inset-block-start: var(--header-total-height);
            height: calc(100% - var(--header-total-height));
        }
    }

    &-Heading {
        text-decoration: none;
        font-weight: 400;
        margin-block-start: 0;
    }

    &-Header {
        @include mobile {
            display: block;
        }
    }

    &-Content {
        background-color: var(--popup-content-background);
        border-radius: 5px;
        padding: var(--popup-content-padding);
        padding-block-end: 0;
        min-width: var(--popup-min-width);
        max-width: calc(var(--content-wrapper-width) * .8);
        max-height: var(--popup-max-height);
        overflow-y: auto;

        &::after {
            content: '';
            display: block;
            padding-block-end: var(--popup-content-padding);
        }

        @include mobile {
            border-radius: 0;
            max-height: 100%;
        }
    }

    & &-CloseBtn {
        position: absolute;
        inset-block-start: -4px;
        inset-inline-end: -6px;
        z-index: 5;

        .CloseIcon {
            height: 30px;
            width: 30px;
        }

        @include mobile {
            inset-block-start: 0px;
        }
    }

    .Loader {
        @include mobile {
            position: fixed;
        }
    }

    .NotificationList {
        width: 100vw;
        inset-block-start: 0;
        inset-inline-start: calc(0px - var(--popup-content-padding));
        position: relative;
    }

    &#DeleteAddress {
        @include mobile {
            justify-content: center;
            align-items: center;
        }

        .Popup-Content {
            inset-block-start: unset;
            inset-inline-end: unset;

            @include desktop {
                padding: 2rem;
            }

            @include mobile {
                padding: 2rem;
                height: fit-content;
                border-radius: 8px;
            }
        }

        .Popup-CloseBtn {
            inset-block-start: 5px;
            cursor: pointer;
        }

        .MyAccountAddressPopup-heading {
            font-weight: 700;
        }

        .MyAccountAddressPopup-popup {
            @include mobile {
                padding: 16px;
            }
        }
    }
}

.SelectCountryPopup {
    @include desktop {
        .Popup-Content {
            max-width: unset;
            max-height: unset;
            width: 100%;
            height: 100%;
        }
    }

    @include mobile {
        inset-block-start: 0px;

        &_isVisible {
            height: 100vh;
            z-index: 801;
        }

        .Popup-Content {
            // inset-block-start: 25%;
            overflow-x: hidden;
            // animation: slideUp 1s cubic-bezier(.075,.82,.165,1);
            // padding-inline: 14px;
            padding-block-start: 14px;
            padding-block-end: 0;
            padding-inline: 0;
        }
    }
}

.Deactivateaccount {
    @include mobile {
        inset-block-start: 0px;

        &_isVisible {
            height: 100vh;
            z-index: 801;
        }
    }
}

@keyframes slideUp {
    from {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.scrollDisabled {
    margin-block-start: 0;
    width: 100%;

    @include desktop {
        position: fixed;
        overflow-y: scroll;
    }

    @include mobile {
        overflow-y: auto;
    }
}

// .Popup_isVisible.willClose .Popup-Content {
//     animation: slideOut 1s forwards;
// }
