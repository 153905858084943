/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ConfigPopup {
    position: fixed;
    background: #4f4f4f82;
    z-index: 900;
    inset-block-start: 0;
    inset-inline-end: 0;
    display: flex;
    inset-block-end: 0;
    inset-inline-start: 0;
    align-items: center;
    justify-content: center;

    .popup {
        display: flex;
        background: #fff;
        flex-direction: column;
        border-radius: 8px;
        max-width: 360px;
        width: 100%;

        .Popup-CloseBtn {
            inset-inline-end: 1rem;
            inset-block-start: 1rem;
            position: absolute;
            cursor: pointer;
            z-index: 99;

            @include mobile {
                inset-inline-end: 5px;
                inset-block-start: 3px;
                margin-block-start: 8px;
                margin-inline-end: 8px;
            }

            svg {
                width: 24px;
                height: 24px;
                fill: var(--color-black);
                padding-block-start: 2px;
            }
        }
    }    
}
