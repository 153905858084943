/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SearchField {
    .Xsearch {
        &-Container {
            @include desktop {
                display: flex;
                width: 100%;
                border: 1px solid #b7b7b7;
                overflow-y: scroll;
                height: calc(100vh - 211px);
            }

            &-details {
                @include desktop {
                    width: 32%;
                    display: flex;
                    flex-direction: column;
                    padding: 0 24px 24px 24px;
                }

                &-block-content {
                    display: flex;
                    flex-wrap: wrap;
                    margin-block-start: 16px;
                    padding-block-end: 8px;
                    border-bottom: 1px solid;
                }

                &-block-heading {
                    letter-spacing: 0;
                    color: var(--primary-base-color);
                    text-transform: capitalize;
                    font-weight: bold;

                    @include desktop {
                        font-size: 16px;
                        padding: 24px 0 0 0;
                    }

                    @include mobile {
                        padding: 16px 0 0;
                    }
                }

                &-name {
                    letter-spacing: 0px;
                    color: #0f0f0f;
                    opacity: 1;
                    font-weight: normal;

                    @include desktop {
                        font-size: 14px;
                    }
                }

                &-tab {
                    padding: 16px 0;

                    &:not(:first-of-type) {
                        border-bottom: 1px solid #ededed;
                    }

                    &:last-child {
                        border-bottom: 1px solid #0f0f0f;
                    }

                    &-block {
                        display: flex;
                        justify-content: space-between;
                    }

                    &-recent-items {
                        padding: 7px 16px;
                        background: #fff5f4;
                        border-radius: 18px;
                        margin-block-end: 16px;
                        word-break: break-all;

                        &:not(:last-of-type) {
                            margin-inline-end: 16px;
                        }
                    }

                    &-block-recent-items {
                        font-weight: normal;
                    }

                    &.search-categories:last-child {
                        border-bottom: unset;
                    }
                }

                &-count {
                    font-weight: normal;
                }
            }

            &-products {
                @include desktop {
                    width: 69%;
                    border-left: 1px solid #9d9d9d;
                    margin-block-start: 24px;
                }

                &-heading {
                    font-size: 16px;
                    letter-spacing: 0;
                    color: var(--primary-base-color);
                    text-transform: capitalize;
                    font-weight: bold;
                    padding: 0 24px;

                    @include mobile {
                        font-size: 14px;
                        padding: 18px 0 0;
                    }
                }

                &-container {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    max-height: unset;
                    grid-gap: 24px;

                    @include desktop {
                        padding: 16px 24px;
                        overflow-y: unset;
                        height: 100%;
                    }

                    @include tablet {
                        grid-template-columns: 1fr 1fr;
                    }

                    @include mobile {
                        grid-gap: 20px;
                        overflow-y: unset;
                        padding: 16px 0;
                        grid-template-columns: 1fr 1fr;
                    }
                }

                .ProductCard .NotifyStock span {
                    @include mobile {    
                        font-size: 12px;
                        line-height: none;
                    }
                }

                .ProductCard {
                    margin-block-end: 0;  

                    &:last-child {
                        margin-block-end: 0;
                    }
                }

                .ProductCard-Figure {
                    height: 200px;

                    img {
                        display: inline;
                        object-fit: cover;
                        height: 200px;

                        @include mobile {
                            height: 112px;
                            border-radius: 8px 8px 0px 0px;
                        }
                    }
                }
            }

            .ProductCard {
                box-shadow: 2px 2px 9px #00000014;    

                &-Figure {
                    width: 100%;
                    height: 100%;
                }

                &-Picture {
                    //width: inherit;
                    width: 0;
                }

                &-FigureReview {
                    @include desktop {
                        padding-inline: 10px;
                    }
                }

                &-Content {
                    @include mobile {
                        height: 250px;
                    }
                }

                &-PriceWrapper {
                    .ProductPrice {
                        &-PriceValue {
                            font-size: 14px!important; /* stylelint-disable-line declaration-no-important */
                        }
                    }
                }

                &-ContentGrid {
                    justify-content: flex-end;

                    @include mobile {
                        flex-direction: column-reverse;
                        align-items: baseline;
                    }
                }
            }
        }
    }
}
