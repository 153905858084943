/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --expandable-content-color: var(--color-karaka);
    --expandable-content-divider-color: var(--primary-divider-color);
    --toggle-button-size: 18px;
}

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    @include mobile {
        border-block-start: 1px solid var(--expandable-content-divider-color);

        &:last-of-type {
            border-block-end: 1px solid var(--expandable-content-divider-color);
        }

        .AddIcon,
        .MinusIcon,
        .ChevronIcon {
            width: var(--toggle-button-size);
            height: var(--toggle-button-size);
        }
    }

    &-Button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        @include mobile {
            padding-block: 14px;
        }
    }

    &-Heading,
    &-SubHeading {
        display: block;
        color: var(--expandable-content-color);
    }

    &-Heading {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        word-break: break-all;
        column-gap: 10px;
        display: flex;

        .flag {
            width: 27px;
            height: 22px;
        }

        @include mobile {
            font-size: 14px;
        }

        @include desktop {
            cursor: inherit;
            font-size: 14px;
            font-weight: bold;
            text-transform: none;
        }
    }

    &-SubHeading {
        font-weight: 400;
        font-size: 10px;
        line-height: 1.1;

        @include mobile {
            font-size: 12px;
        }
    }

    &-Content {
        // Disabled as it prevents z-index use inside
        // will-change: max-height, padding, opacity;
        transition-property: opacity  max-height;
        transition-duration: 200ms;
        overflow: hidden;

        @include mobile {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        @include desktop {
            margin-block-start: 0;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        &_isContentExpanded {
            @include expanded-content;

            @include mobile {
                @include expanded-content;
            }
        }
    }

    @include mobile {
        &_isWithoutBorder {
            border: 0;

            &:last-of-type {
                border: 0;
            }
        }
    }

    &.isDesktop {
        .ExpandableContent {
            &-Button {
                @include desktop {
                    padding: 2rem 0;
                    cursor: default;

                    svg {
                        display: none;
                    }
                }
            }

            &-Heading {
                @include mobile {
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            &-Content {
                li {
                    margin-block-end: 16px
                }

                @include desktop {
                    @include expanded-content;
                }
            }
        }
    }
}
